import React, {useEffect, useState} from 'react';

const ContentSection = () => {
    const [sections, setSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        fetch('data.json')
            .then(response => response.json())
            .then(data => setSections(data.sections));
    }, []);

    const handleSidebarToggle = () => {
        setSidebarOpen(prev => !prev); // Toggle the state to reflect the sidebar visibility
    };

    const mainContentStyle = {
        marginLeft: sidebarOpen ? '20vh' : '5vh',
        width: sidebarOpen ? 'calc(100% - 25vh)' : 'calc(100% - 10vh)', // Adjust this based on your exact layout needs
    };

    return (
        <section id="content" className="section">
            <div id="menuIndicator" className="menu-indicator" onClick={handleSidebarToggle}></div>
            <div id="sideMenu" className="side-menu" style={{width: sidebarOpen ? '20vh' : '0'}}>
                <ul id="menuList">
                    {sections.map((section, index) => (
                        <li key={index} onClick={() => {
                            if (selectedSection === section.content) {
                                setSelectedSection(null);
                            } else {
                                setSelectedSection(section.content);
                            }
                        }}>
                            {section.name}
                        </li>
                    ))}
                </ul>
            </div>
            <div id="mainContent" className="main-content" style={mainContentStyle}>
                {selectedSection}
            </div>
        </section>
    );
};

export default ContentSection;
