import React, { useEffect, useRef } from 'react';

const Section = ({ id, title, subtitle, content }) => {
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    const { current } = sectionRef;
                    if (current) {
                        current.style.opacity = entry.isIntersecting ? 1 : 0;
                    }
                });
            },
            { threshold: 0.7 }
        );

        const currentSection = sectionRef.current;
        if (currentSection) {
            observer.observe(currentSection);
        }

        return () => {
            if (currentSection) {
                observer.unobserve(currentSection);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} id={id} className="section">
            <h1>{title}</h1>
            <p>{subtitle}</p>
            {content && <p>{content}</p>}
        </section>
    );
};

export default Section;
