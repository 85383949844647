import React from 'react';
import './App.css';  // Assuming your CSS is adapted for React and named App.css
import Section from './Section'; // Section component for each section
import ContentSection from './ContentSection'; // Section component for each section

function App() {
  return (
    <div className="App">
      <Section id="welcome-page" title="WELCOME TO PANK.LU" subtitle="scroll down to see more" />
      <Section id="about-page" title="Hi, I'm Alex P. This is my dummy website. I have not decided yet what I am going to share here, but hopefully there will be something interesting soon :D" subtitle="scroll down to see more" />
      <ContentSection /> {/* Additional component for content management */}
    </div>
  );
}

export default App;
